<template>
  <div class="list-wrapper section-fiche-travaux">
    <div class="flex-icon-block" v-for="item in travaux" :key="item">
      <img :src="travauxData[item].icon"/>
      <div>
        <p class="big-text">{{ travauxData[item].label }}</p>
        <p class="annotation" v-if="item === 'renoAmpleur' && renoAmpleurAnnotation" v-html="renoAmpleurAnnotation"></p>
      </div>
    </div>
  </div>
</template>

<script>
import isolationIco from '@/assets/module1/part2/lesson/isolation-ico.svg'
import menuiserieIco from '@/assets/module1/part2/lesson/menuiserie-ico.svg'
import chauffageIco from '@/assets/module1/part2/lesson/chauffage-ico.svg'
import ventilationIco from '@/assets/module1/part2/lesson/ventilation-ico.svg'
import renoGlobalIco from '@/assets/module1/part2/lesson/reno-globale-ico.svg'
import travauxInduitIco from '@/assets/module1/part2/lesson/outils-bg-ico.svg'

export default {
  name: 'SectionFicheTravaux',
  props: {
    travaux: {
      type: Array,
      required: true
    },
    renoAmpleurAnnotation: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      travauxData: {
        isolation: {
          label: this.$t('module1.part2.travaux.isol'),
          icon: isolationIco
        },
        menuiserie: {
          label: this.$t('module1.part2.travaux.menuiserie'),
          icon: menuiserieIco
        },
        chauffage: {
          label: this.$t('module1.part2.travaux.chauffage'),
          icon: chauffageIco
        },
        vmc: {
          label: this.$t('module1.part2.travaux.vmc'),
          icon: ventilationIco
        },
        vmcSimple: {
          label: this.$t('module1.part2.travaux.vmcSimple'),
          icon: ventilationIco
        },
        induits: {
          label: this.$t('module1.part2.travaux.travauxInduit'),
          icon: travauxInduitIco
        },
        renoAmpleur: {
          label: this.$t('module1.part2.travaux.renoAmpleur'),
          icon: renoGlobalIco
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
