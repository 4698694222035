<template>
  <div class="section-fiche-aide">
    <div class="section-fiche-aide-head">
      <img :src="icon">
      <p class="label" >{{ label }}</p>
    </div>
    <div class="section-fiche-aide-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionFicheAide',
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.section-fiche-aide {
  width: 100%;
  .section-fiche-aide-head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $space-sm;
    .label {
      color: $c-secondary;
      font-family: $ff-big;
      font-size: $fz-xl;
      font-weight: $fw-s;
      margin-left: $space-s;
    }
  }
  .section-fiche-aide-content {
    margin-top: $space-s;
    text-align: left;
    font-size: $fz-s;
    .left-content {
      text-align: left;
    }
    .list-wrapper {
      margin: $space-m 0;
    }
    .annotation {
      color: $c-secondary;
      font-size: $fz-s;
      font-weight: $fw-m;
    }
    .checklist {
      .item + .item {
        margin-top: $space-m;
      }
      .item {
        display: flex;
        align-items: flex-start;
        text-align: left;
        padding-right: $space-m;
        &:before {
          content: '';
          flex-shrink: 0;
          background-image: url('../../../../assets/module1/part2/lesson/check-ico.svg');
          background-repeat: no-repeat;
          background-size: 14px;
          width: 15px;
          margin-right: $space-m;
          height: 20px;
        }
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .section-fiche-aide {
    .section-fiche-aide-head {
      justify-content: flex-start;
    }
  }
}
</style>
